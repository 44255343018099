.biometric-verification {
    .banner {
        padding: 8rem 0px 3rem 0px;
        background-image: url('https://www.circle.com/hubfs/background/hero-yield-apple.png');
        background-size: cover;
        .banner-inner {
            width: min(1200px, 90%);
            margin: auto;
            display: flex;
            flex-direction: column;
            gap: 0.75rem;
            > * {
                margin: 0px;
            }
            h2 {
                font-size: 1.73rem;
            }
            h1 {
                font-size: 4.5rem;
            }
            input {
                background: #8656ef;
                border: 0;
                border-radius: 5px;
                color: #fff;
                cursor: pointer;
                display: block;
                font-family: Circular,sans-serif;
                font-size: 14px!important;
                font-weight: 900;
                letter-spacing: 1.4px;
                line-height: 1.214;
                margin: 0 0 1.4rem;
                outline: 0;
                padding: 12px 18.5px;
                text-align: center;
                text-decoration: none!important;
                text-transform: uppercase;
                transition: background .3s ease,border .3s ease;
                width: fit-content;
                background: #29233b;
                border: 1px solid #29233b;
                &:hover {
                    background: #4e4763;
                    border: 1px solid transparent;
                }
            }
            a {
                color: #29233b;
                font-size: 0.8rem;
                font-weight: 700;
                letter-spacing: 1.13px;
                position: relative;
                cursor: pointer;
                &::after {
                    content: '';
                    position: absolute;
                    height: 2px;
                    width: 100%;
                    background-color: #29233b;
                    transform: scale(0);
                    transform-origin: left;
                    transition: 0.3s transform;
                    left: 0;
                    bottom: -2px;
                }
                &:hover {
                    &::after {
                        transform: scale(1);
                    }
                }
            }
        }
    }
    .features {
        margin: 3rem auto;
        width: min(1200px, 90%);
        h1 {
            font-size: 2.5rem;
        }
        > p {
            font-size: 1.8rem;
            width: min(800px, 100%);
        }
        .feature-list {
            display: flex;
            flex-direction: column;
            margin: 3rem 0px;
            gap: 1rem;
            .feature {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                img {
                    width: 48px;
                    height: 48px;
                }
                p {
                    font-size: 1.2rem;
                }
            }
        }
    }
    .content {
        display: flex;
        margin: 3rem auto;
        align-items: center;
        gap: 1.75rem;
        .content-text {
            display: flex;
            flex-direction: column;
            span {
                background: #f1f8ff;
                color: #1894e8;
                font-size: 0.8rem;
                font-weight: 700;
                width: fit-content;
                padding: 0.25rem 0.5rem;
            }
            h2 {
                font-size: 2.5rem;
            }
            p {
                font-size: 1rem;
                a {
                    color: #8656ef;
                }
            }
            > a {
                border-bottom: 2px solid transparent;
                color: #8656ef;
                font-size: 0.9rem;
                font-weight: bold;
                letter-spacing: 1.4px;
                text-transform: uppercase;
            }
        }
    }
    .trust {
        margin: 3rem 0px;
        width: min(1200px, 90%);
        margin: auto;
        h2 {
            font-size: 2.5rem;
        }
        .trust-list {
            margin: 3rem 0px;
            display: flex;
            gap: 1.5rem;
            .trust-item {
                border-radius: 10px;
                box-shadow: 0 0 10px 0 #f1ebff;
                flex: 1;
                transition: 0.3s box-shadow;
                img {
                    width: 100%;
                }
                b, p {
                    padding: 0px 1rem;
                }
                &:hover {
                    box-shadow: 0 3px 20px 0 #e3d5fe;
                }
            }
        }
        a {
            border-radius: 5px;
            color: #fff;
            cursor: pointer;
            display: block;
            font-family: Circular,sans-serif;
            font-size: 14px!important;
            font-weight: 900;
            letter-spacing: 1.4px;
            line-height: 1.214;
            outline: 0;
            text-align: center;
            text-decoration: none!important;
            text-transform: uppercase;
            transition: background .3s ease,border .3s ease;
            width: fit-content;
            padding: 0.5rem 1rem;
            background: transparent;
            border: 1.5px solid #8656ef;
            box-shadow: none;
            color: #8656ef!important;
            margin-bottom: 12px;
            &:hover {
                background: #9f72ff;
                border: 1.5px solid #9f72ff;
                box-shadow: none;
                color: #fff!important;
            }
        }
    }
    .rates {
        display: flex;
        flex-direction: column;
        margin: 3rem auto;
        width: min(1200px, 90%);
        h2{
            font-size: 1.75rem;
        }
        > p a {
            color: #9f72ff;
        }
        .rate-list {
            margin: 2rem auto;
            display: flex;
            width: min(1100px, 100%);
            justify-content: center;
            gap: 3rem;
            .rate {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                h4 {
                    font-size: 0.8rem;
                }
                .rate-nested-list {
                    display: flex;
                    .rate-nested {
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        padding: 0px 1rem;
                        h1 {
                            font-size: 3.75rem;
                            font-weight: 300;
                            letter-spacing: -3.39px;
                            line-height: 1.196721;
                            margin: 0px;
                        }
                        h3 {
                            font-size: 1.125rem;
                            font-weight: 700;
                            letter-spacing: -.4px;
                            line-height: 1.3333333;
                        }
                        &:first-child {
                            border-right: 1px solid #c7c5d1;
                        }
                    }
                }
            }
        }
        span {
            font-size: 0.75rem;
        }
    }
    .signup {
        margin: 10rem auto;
        text-align: center;
        width: min(1000px, 90%);
        h1 {
            font-size: 2.5rem;
            span {
                background: -webkit-linear-gradient(left,#5fbfff,#b090f5);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                -webkit-box-decoration-break: clone;
            }
        }
        p {
            font-size: 1.25rem;
        }
        .signup-actions {
            margin: 1.5rem 0px;
            display: flex;
            justify-content: center;
            gap: 1rem;
            flex-wrap: wrap;
        }
        a {
            background: #8656ef;
            border: 0;
            border-radius: 5px;
            color: #fff;
            cursor: pointer;
            display: block;
            font-family: Circular,sans-serif;
            font-size: 14px!important;
            font-weight: 900;
            letter-spacing: 1.4px;
            line-height: 1.214;
            margin: 0 0 1.4rem;
            outline: 0;
            padding: 12px 18.5px;
            text-align: center;
            text-decoration: none!important;
            text-transform: uppercase;
            transition: background .3s ease,border .3s ease;
            width: fit-content;
            &:hover {
                background: #9f72ff;
            }
            &:last-child {
                background: transparent;
                color: #8656ef;
                border: 1px solid #8656ef;
                &:hover {
                    background: #9f72ff;
                    border: 1px solid #9f72ff!important;
                    color: #fff!important;
                }
            }
        }
    }
    .form {
        margin: 3rem auto 17.5rem auto;
        width: min(600px, 90%);
        h1 {
            text-align: center;
            font-size: 2.5rem;
        }
        p {
            text-align: center;
            font-size: 1rem;
        }
        .input {
            margin: 2rem 0px;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            label {
                font-size: 0.8rem;
                font-weight: bold;
            }
            input, select {
                background-color: transparent;
                border: 0px;
                height: 50px;
                outline: none;
                transition: 0.3s background-color;
                padding: 0.5rem 1rem;
                font-size: 1.125rem;
                border-radius: 10px;
                font-weight: normal;
                &:focus {
                    background-color: #f1f0f5;
                }
            }
        }
        span {
            font-size: 0.75rem;
            a {
                color: #8656ef;
            }
        }
        input[type='button'] {
            margin-top: 1rem;
            display: block;
            background: #8656ef;
            border: 0;
            border-radius: 5px;
            color: #fff;
            cursor: pointer;
            display: block;
            font-family: Circular,sans-serif;
            font-size: 14px!important;
            font-weight: 900;
            letter-spacing: 1.4px;
            line-height: 1.214;
            outline: 0;
            padding: 12px 18.5px;
            text-align: center;
            text-decoration: none!important;
            text-transform: uppercase;
            transition: background .3s ease,border .3s ease;
            width: fit-content;
            &:hover {
                background: #9f72ff;
            }
        }
    }
    @media (max-width: 1002px) {
        .banner .banner-inner{
            h1 {
                font-size: 3rem;
            }
        }
        .features {
            h1 {
                font-size: 2rem;
            }
            p {
                font-size: 1.25 rem;
            }
        }
        .content {
            flex-direction: column;
            gap: 0px;
            .content-image {
                width: 90%;
                order: 2;
                img {
                    width: 100%;
                }
            }
            > p {
                order: 3;
            }
        }
        .content-reverse {
            .content-text {
                order: 1;
            }
        }
        .content-bottom {
            .content-image {
                order: 1;
            }
            .content-text {
                order: 2;
                gap: 0.5rem;
                display: flex;
                flex-direction: column;
            }
        }
        .trust {
            h2 {
                font-size: 2rem;
            }
            .trust-list {
                flex-direction: column;
            }
        }
        .rates {
            .rate-list {
                flex-direction: column;
            }
        }
        .signup {
            h1 {
                font-size: 2rem;
            }
        }
        .form {
            h1 {
                font-size: 2rem;
            }
        }
    }
}
