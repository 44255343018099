.about {
    margin: 10rem 0px;
    .about-header {
        text-align: center;
        h1 {
            font-size: 3.75rem;
            width: min(900px, 90%);
        }
        p {
            width: min(1100px, 90%);
            margin: auto;
            font-size: 1.25rem;
        }
    }
    .content {
        margin: 10rem auto;
        width: min(1100px, 90%);
        display: flex;
        align-items: center;
        gap: 2rem;
        justify-content: space-between;
        .content-text {
            width: min(450px, 100%);
            display: flex;
            flex-direction: column;
            gap: 1rem;
            h3 {
                font-size: 2.6rem;
            }
            p {
                line-height: 1.5rem;
                font-size: 1.125rem;
                &:nth-child(2) {
                    margin-bottom: -1rem;
                }
            }
        }
        .content-image {
            width: 50%;
            img {
                width: 90%;
            }
        }
    }
    .content-list {
        margin: 10rem auto;
        width: min(900px, 90%);
        display: flex;
        flex-direction: column;
        gap: 5rem;
        h3 {
            font-size: 2.6rem;
            text-align: center;
        }
        .list {
            display: flex;
            gap: 1.5rem;
            justify-content: space-between;
            .list-item {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                width: min(300px, 100%);
                b {
                    font-size: 1.125rem;
                }
                p {
                    margin-top: -0.75rem;
                    font-size: 1rem;
                }
            }
        }
    }
    .content-founders {
        margin: 10rem auto;
        width: min(700px, 90%);
        display: flex;
        flex-direction: column;
        gap: 5rem;
        h3 {
            font-size: 2.6rem;
            text-align: center;
        }
        .founders-text {
            margin-top: -4.25rem;
            display: flex;
            flex-direction: column;
            p {
                line-height: 1.5rem;
            }
        }
    }
    .content-join-us {
        max-width: 90%;
        text-align: center;
        margin: 10rem auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        p {
            margin-top: -0.5rem;
        }
        a {
            background: #06f;
            padding: 0.7rem 2.25rem;
            color: #fff !important;
            cursor: pointer;
            border-radius: 9999px;
            font-weight: bold;
            &:hover {
                background: #0052cc;
            }
        }
    }
    @media (max-width: 1032px) {
        .content {
            flex-direction: column-reverse;
            .content-image {
                width: 100%;
                img {
                    width: 90%;
                }
            }
        }
        .content-reverse {
            flex-direction: column;
        }
        .content-list {
            .list {
                width: min(550px, 100%);
                flex-direction: column;
                margin: auto;
                .list-item {
                    width: 100%;
                    }
                }
        }
    }
    @media (max-width: 668px) {
        > * {
            margin: 5rem auto !important;
        }
        .about-header {
            h1 {
                font-size: 2.4rem;
            }
            p {
                font-size: 1.125rem;
            }
        }
        .content {
            .content-text {

            h3 {
                font-size: 1.6rem;
            }
            p {
                font-size: 1rem;
            }
        }
        }
        .content-list {
            gap: 1.5rem;
            text-align: center;
            h3 {
                font-size: 1.6rem;
            }
        }
        .content-founders {
                h3 {
                    font-size: 1.6rem;
                }
                .founders-text {
                    p {
                        font-size: 1rem;
                    }
                }
            .founders-image {
                width: 80%;
                margin: auto;
            }
        }
    }
}
