img {
  max-width: 100%;
}
h1 {
  font-size: 3.75rem;
  font-weight: 700;
  line-height: 1;
  padding: 0;
  margin: 0.5em 0;
}
@media (max-width: 600px) {
  h1 {
    font-size: 2.4rem;
  }
}
h1.small-title {
  font-size: 2.917rem;
  font-weight: 700;
  line-height: 1.1;
  padding: 0;
  margin: 0;
}
@media (max-width: 600px) {
  h1.small-title {
    font-size: 2rem;
  }
}
.footer-logo {
  width: 140px;
}
h2 {
  font-size: 2.917rem;
  font-weight: 700;
  line-height: 1.1;
  padding: 0;
  margin: 0;
}
@media (max-width: 600px) {
  h2 {
    font-size: 2rem;
  }
}
h3 {
  font-size: 2.667rem;
  font-weight: 700;
  line-height: 1.1;
  padding: 0;
  margin: 0;
}
@media (max-width: 600px) {
  h3 {
    font-size: 1.6rem;
  }
}
.h3-sutton-larger {
  font-size: 32px;
}
.h3-sutton-smaller {
  font-size: 24px;
}
h4 {
  font-size: 1.74rem;
  font-weight: 700;
  line-height: 1.2;
  padding: 0;
  margin: 0;
}
@media (max-width: 600px) {
  h4 {
    font-size: 1.2rem;
  }
}
h5 {
  font-size: 1.333rem;
  font-weight: 700;
  line-height: 1.2;
  padding: 0;
  margin: 0;
}
@media (max-width: 600px) {
  h5 {
    font-size: 1rem;
  }
}
p {
  line-height: 1.3;
  padding: 0;
  margin: 12px 0;
}
@media (max-width: 1024px) {
  p {
    font-size: 18px;
  }
}
@media (max-width: 600px) {
  p {
    font-size: 16px;
  }
}
p.no-margin {
  margin: 0;
}
.p-callout {
  font-size: 18px;
  font-weight: 700;
}
.p-large {
  font-size: 1.333rem;
  line-height: 1.4;
}
.li-small-text {
  font-size: 0.75em;
  font-weight: 600;
  line-height: 2;
}
@media (max-width: 600px) {
  .li-small-text {
    font-size: 0.6em;
  }
}
.font-large {
  font-size: 2.5em;
}
.font-medium {
  font-size: 1.6em;
}
.font-medium-small {
  font-size: 1.25em;
}
.font-small {
  font-size: 0.75em;
}
.font-tiny {
  font-size: 0.6em;
}
.script {
  font-family: "AlbertScript", "Work sans", sans-serif, cursive, "Work sans",
    sans-serif, sans-serif;
}
.bold,
.subtitle {
  font-weight: 700;
}
.semibold {
  font-weight: 600;
}
b {
  font-weight: 600;
}
.subtitle {
  padding-top: 2em;
}
.underline-subtitle {
  text-decoration: underline;
  padding-top: 1em;
}
.disclosures {
  font-size: 0.75em;
  line-height: 1.4;
  color: #a2a5b2;
}
.disclosures a {
  text-decoration: underline;
  color: inherit;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-left-center {
  text-align: left;
}
@media (max-width: 1024px) {
  .text-left-center {
    text-align: center;
  }
}
.text-center {
  text-align: center;
}
.break-word {
  word-wrap: break-word;
}
.text-center-left {
  text-align: center;
}
@media (max-width: 1024px) {
  .text-center-left {
    text-align: left;
  }
}
.text-center-left-small {
  text-align: center;
}
@media (max-width: 768px) {
  .text-center-left-small {
    text-align: left;
  }
}
@media (max-width: 1024px) {
  .text-center-tablet {
    text-align: center;
  }
}
@media (max-width: 768px) {
  .text-center-mobile {
    text-align: center;
  }
}
@media (min-width: 1025px) {
  .inline-desktop {
    display: inline;
  }
}
html,
body {
  font-family: "Work sans", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  color: #333;
  text-rendering: optimizeLegibility;
  background: #fff;
  margin: 0;
  padding: 0;
  height: 100%;
  box-sizing: border-box;
}
@media (max-width: 1024px) {
  html,
  body {
    font-size: 16px;
  }
}
body {
  display: flex;
  flex-direction: column;
  width: 100vw;
  overflow-x: hidden;
  margin: 0px;
}
.wrap {
  flex: 1 0 auto;
}
::selection {
  background: #e8f4fc;
}
a,
a:visited {
  color: #06f;
  text-decoration: none;
}
a:hover,
a:active {
  color: #0052cc;
}
.clear {
  clear: both;
}
hr {
  border: 1px solid #f2f3f7;
}
hr.no-margin {
  margin: 0;
}
@media (max-width: 600px) {
  .hide-on-mobile {
    display: none;
  }
}
.circle-plus line,
.circle-plus svg,
.img-card img,
.round-card.black a:hover,
.round-card .img-scale,
.sms-modal,
.no-results .no-results-action-text,
.job-dropdown-header-caret i,
.video-player,
.video-player-container,
.video-play-button i,
.video-play-button,
.team-tile,
.text-app button,
.learn-more:hover span:before,
.learn-more:active span:before,
.learn-more:hover,
.learn-more:active,
.learn-more span:before,
.learn-more:visited span:before,
.backdrop,
nav a,
nav a:visited,
nav .logo,
nav,
.navbar-offset,
a,
a:visited {
  transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  -o-transition: 0.2s linear;
  -webkit-transition: 0.2s linear;
}
.text-app button,
.text-app input,
.button-dark,
.button,
.button-caps,
.button-dropdown {
  border-radius: 0.5em;
}
.button-caps {
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 600;
}
.smart-banner-wrapper a,
.main-section a {
  color: inherit;
  text-decoration: inherit;
  transition: none;
}
.smart-banner-wrapper a:hover,
.main-section a:hover,
.smart-banner-wrapper a:active,
.main-section a:active {
  color: inherit;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.navbar-offset {
  padding-top: 5.625rem;
}
.navbar-offset.shrink {
  padding-top: 3rem;
}
nav {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4.625rem;
  width: 100vw;
  margin: 0;
  background-color: white;
  border-bottom: 1px solid transparent;
  z-index: 500;
  box-sizing: border-box;
}
nav.shrink {
  padding-top: 4px;
  height: 3rem;
  background: white !important;
}
nav .logo {
  width: 140px;
}
nav .logo.shrink {
  width: 120px;
}
nav a,
nav a:visited {
  display: block;
  text-decoration: none;
  outline: none;
}
nav a:hover,
nav a:visited:hover {
  color: #06f;
}
.col-nav {
  padding: 0 0 0 5%;
  float: left;
}
.col-nav:nth-child(even) {
  padding: 0 5% 0 0;
}
.backdrop {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
}
.backdrop.enter {
  display: block;
  animation: fade-in 0.2s ease;
}
.dropdown-desktop {
  position: fixed;
  top: 0;
  bottom: 0;
  right: -500px;
  width: 500px;
  background-color: #fff;
  color: #333;
  text-align: left;
}
.dropdown-desktop.enter {
  transform: translateX(-500px);
  transition: transform 0.4s;
  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.3));
}
.dropdown-desktop.exit {
  transform: translateX(500px);
  transition: transform 0.4s;
}
.dropdown-desktop > .close-icon {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.75em 5vw 0px 40px;
  cursor: pointer;
  z-index: 1;
}
.sections {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 80px;
  overflow: auto;
}
.features-section-desktop {
  border-bottom: 1px solid #cfd1dc;
}
.features-section-desktop > a {
  display: block;
  color: #333;
  font-size: 1.6em;
  font-weight: 500;
  padding-bottom: 20px;
}

.features-section-desktop > button {
  display: flex;
  align-items: center;
  color: #333;
  font-size: 1.6em;
  font-weight: 500;
  padding-bottom: 20px;
}
.features-section-desktop > a:last-child {
  padding-bottom: 40px;
}
.company-info-section-desktop {
  padding: 30px 0 80px;
}
.company-info-section-desktop > a {
  display: block;
  color: #333;
  font-size: 1em;
  font-weight: 500;
  padding-bottom: 20px;
}
.app-links-section-desktop > a {
  display: block;
  padding-bottom: 12px;
}
.app-links-section-desktop > a:last-child {
  padding-bottom: 0px;
}
.app-links-section-desktop > a > img {
  width: 180px;
}
#desktop-nav {
  display: inline-block;
}
@media (max-width: 1150px) {
  #desktop-nav {
    display: none;
  }
}
#mobile-nav {
  display: none;
}
@media (max-width: 1150px) {
  #mobile-nav {
    display: inline-block;
  }
}
.dropdown-mobile {
  display: none;
  position: fixed;
  top: 4.625rem;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5% 0;
  background-color: #fff;
  color: #333;
  text-align: left;
  overflow: auto;
}

.topSpace {
  top: 3rem;
}

.features-section-mobile {
  margin-top: -16px;
  margin-bottom: 16px;
}
.dropdown-mobile.shrink .features-section-mobile {
  margin-top: 0;
}
.features-section-mobile > a {
  display: block;
  color: #333;
  font-size: 1.25em;
  font-weight: 500;
  padding: 16px 0 16px 5%;
}

.features-section-mobile > button {
  display: flex;
  align-items: center;
  color: #333;
  font-size: 1.25em;
  font-weight: 500;
  padding: 16px 0 16px 5%;
}
.company-info-section-mobile {
  margin-bottom: 35px;
  padding: 0 5%;
  margin-left: -7px;
}
.company-info-section-mobile > a {
  display: inline-block;
  color: #a2a5b2;
  font-size: 1em;
  font-weight: 500;
  padding: 0 7px;
}
.dropdown-mobile > .get-app-btn {
  display: block;
  color: #fff;
  font-weight: 700;
  background-color: #06f;
  border-radius: 50px;
  height: 52px;
  line-height: 52px;
  margin: 0 5%;
}
.dropdown-mobile > .get-app-btn:hover {
  color: #fff;
  background-color: #0052cc;
}
.show {
  display: block;
}
.hide {
  display: none;
}
.wrap {
  flex: 1 0 auto;
}
footer {
  font-size: 0.75rem;
  background: #f8f8fa;
  flex-shrink: 0;
  padding: 12em 10% 6em 10%;
  line-height: 2.25;
}
footer .footer-content {
  width: 80%;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  footer {
    padding: 12em 10% 10em 10%;
  }
}
/* footer .logo{
  width:40px;
  max-width:50%
} */
footer a:link,
footer a:visited {
  color: #333;
}
footer a:hover,
footer a:active {
  color: #06f;
}
footer .social-icons a {
  font-size: 1.6em;
  padding: 0 0.3em 0em 0;
  line-height: 1.9;
}
footer .info-icon {
  height: 1.3em;
  width: 1.3em;
  padding-right: 5px;
  vertical-align: middle;
}
@media (max-width: 600px) {
  footer .info-icon {
    height: 1em;
    width: 1em;
    padding-right: 2px;
  }
}
footer .disclosures-header {
  font-size: 1.6em;
  font-weight: 600;
  color: #747680 !important;
  vertical-align: middle;
}
@media (max-width: 600px) {
  footer .disclosures-header {
    font-size: 1em;
  }
}
@media (max-width: 375px) {
  footer .disclosures-header {
    font-size: 4vw;
  }
}
footer .disclosures-header .forward-icon {
  font-weight: 400;
  font-size: 18px;
  line-height: 25.2px;
}
@media (max-width: 600px) {
  footer .disclosures-header .forward-icon {
    font-size: 1em;
  }
}
@media (max-width: 375px) {
  footer .disclosures-header .forward-icon {
    font-size: 4vw;
  }
}
footer .disclosures {
  margin: 16px 0;
  font-size: 1em;
  line-height: 1.3;
  color: #747680;
}
footer .disclosures a {
  text-decoration: underline;
  color: inherit;
}
footer .disclosures b {
  font-weight: 600;
}
.app-buttons {
  display: inline-block;
}
.app-buttons img {
  min-width: 100px;
  width: calc(80% - 0.5em);
  max-width: 120px;
  float: left;
  margin: 0.5em;
}
.app-buttons img:first-child {
  margin-left: 0;
}
.spacer-tiny {
  padding: 0.25em 0;
}
.spacer-x-small {
  padding: 0.5em 0;
}
.spacer-small {
  padding: 1em 0;
}
.spacer-medium {
  padding: 2em 0;
}
.spacer-medium-large {
  padding: 3em 0;
}
.spacer-large {
  padding: 4em 0;
}
.spacer-x-medium-large {
  padding: 5em 0;
}
.spacer-x-large {
  padding: 6em 0;
}
@media (max-width: 1024px) {
  .spacer-desktop-only {
    padding: 0;
  }
}
@media (max-width: 768px) {
  .spacer-desktop-and-tablet-only {
    padding: 0;
  }
}
@media (max-width: 768px) {
  .spacer-tablet-only {
    padding: 0;
  }
}
@media (min-width: 1025px) {
  .spacer-tablet-only {
    padding: 0;
  }
}
@media (min-width: 1025px) {
  .spacer-mobile-only {
    padding: 0;
  }
}
.small-spacer-large-for-nav .spacer-large:nth-child(1) {
  padding: 1.5em 0;
}
.bg-genius {
  background-color: #fafafa;
}
.bg-gray-light {
  background-color: #f2f3f7;
}
.font-color-dark-grey-1 {
  color: #747680;
}
button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
button,
.button,
.button-caps,
.button-dropdown,
.button-thick,
.button-dark,
.button-thin,
.button-cta-wrapper,
.learn-more {
  font-weight: 600;
}
button:focus {
  outline: none;
}
.button,
.button-caps,
.button-dropdown {
  background: #06f;
  padding: 0.7em 1.5em;
  color: #fff !important;
  cursor: pointer;
  border-radius: 84px;
}
.button.wide,
.button-caps.wide,
.button-dropdown.wide {
  padding: 0.7em 2.3em;
}
@media (max-width: 600px) {
  .button.mobile-expand,
  .button-caps.mobile-expand,
  .button-dropdown.mobile-expand {
    display: block;
  }
}
.button-thin {
  padding: 0.5em 1em;
}
.button-thick {
  padding: 1em 1.25em;
}
.button:hover,
.button-caps:hover,
.button-dropdown:hover {
  background: #0052cc;
  cursor: pointer;
}
.button-dark {
  font-size: 0.75em;
  background: #333;
  border: thin solid #333;
  outline: none;
  padding: 0.7em 1.5em;
  color: #fff !important;
}
.button-dark:hover {
  background: #000;
  border-color: #000;
}
.button-dark a,
.button-dark a:visited {
  color: #fff;
}
.button-dark a:hover,
.button-dark a:active {
  color: #fff;
}
.button-dropdown {
  margin: 0 5% 5% 5%;
}
.button-cta-wrapper h5 {
  color: #a2a5b2;
  margin-bottom: 10px;
}
.button-cta-wrapper .button {
  text-align: center;
  padding: 20px 0px;
  margin: 0px auto;
  font-size: 18px;
}
.learn-more-container {
  line-height: 1.4;
  margin: 1.35em 0;
}
.learn-more,
.learn-more:visited {
  color: #333;
}
.learn-more span:before,
.learn-more:visited span:before {
  content: "";
  margin-right: 0;
}
.learn-more:hover,
.learn-more:active {
  color: #111;
}
.learn-more:hover span:before,
.learn-more:active span:before {
  content: "";
  margin-right: 0.2em;
}
.learn-more:hover::after,
.learn-more:active::after {
  background: #111;
}
.learn-more:after {
  display: block;
  content: "";
  height: 3px;
  background: #333;
  border-radius: 3px;
  position: absolute;
  width: 7em;
}
.genius-link,
.genius-link:visited {
  color: #333;
  text-decoration: underline;
}
.genius-link:hover,
.genius-link:visited:hover {
  color: #111;
}
.text-app input {
  background: #f2f3f7;
  border: 1px solid #f2f3f7;
  padding: 0 1em;
  width: 40%;
  min-width: 11em;
  color: #333;
  font-family: "Work sans", sans-serif;
  font-size: 16px;
  height: 48px;
  box-sizing: content-box;
}
.text-app input.bg-white {
  background: #fff;
}
@media (min-width: 601px) {
  .text-app input {
    margin-bottom: 1em;
  }
}
@media (max-width: 600px) {
  .text-app input {
    width: 100%;
    margin-top: 1em;
    box-sizing: border-box;
  }
}
@media (max-width: 375px) {
  .text-app input {
    font-size: 0.85em;
  }
}
.text-app input:focus,
.text-app button {
  outline: none;
  color: #333;
}
.text-app button {
  background: #06f;
  min-width: 160px;
  font-size: 18px;
  border: 1px solid #06f;
  padding: 0 1.3em;
  color: #fff;
  font-family: "Work sans", sans-serif;
  height: 48px;
}
@media (max-width: 600px) {
  .text-app button {
    padding: 0 0.8em;
    font-weight: 700;
    width: 100%;
    margin-top: 1em;
  }
}
.text-app button.success {
  background: #0052cc;
  border: 1px solid #0052cc;
}
.text-app button:hover:not(:disabled) {
  background: #0052cc;
  border: 1px solid #0052cc;
  cursor: pointer;
}
.text-me {
  font-size: 16px;
  position: relative;
  top: -0.1em;
}
.text-me.spinner {
  top: 0;
  font-size: 18px;
}
.text-me .imo-spinner8:before {
  font-size: 1rem;
  display: inline-block;
  animation: spin 1s infinite;
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.text-me .fa-check-circle {
  margin-left: 10px;
}
.callbackMsg {
  font-size: 0.75em;
  line-height: 0.75em;
}
@media (max-width: 375px) {
  .callbackMsg {
    line-height: 2em;
  }
}
.sub-title {
  margin-top: 0;
}
.sub-title .icon {
  font-size: 60%;
}
.sub-title .icon-bg {
  font-size: 1.45em;
  opacity: 0.2;
}
.sub-title.success {
  color: #0b874b;
}
.sub-title.success i {
  color: #0fbb68;
}
.sub-title.error {
  color: #ef0400;
}
.text-app-img {
  width: 90%;
  max-width: 90%;
}
@media (max-width: 1024px) {
  .text-app-img {
    width: 100%;
    max-width: 100%;
  }
}
.text-app-container {
  width: 50%;
  margin: 0 auto;
}
@media (max-width: 1200px) {
  .text-app-container {
    width: 60%;
  }
}
@media (max-width: 1024px) {
  .text-app-container {
    width: 100%;
  }
}
.splash-icon {
  width: auto !important;
  height: 30px;
}
@media (min-width: 1025px) {
  .splash-icon.bottom {
    position: absolute;
    bottom: 0;
    margin-bottom: 5px;
  }
}
@media (min-width: 1025px) {
  .splash-icon-container {
    position: relative;
    height: 30px;
    width: 30px;
  }
  .splash-icon-container.medium {
    height: 34px;
  }
  .splash-icon-container.large {
    height: 40px;
  }
}
.splash-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: auto !important;
  max-height: 600px !important;
}
.splash-img.large {
  max-height: 700px !important;
}
.splash-img.small {
  max-height: 475px !important;
}
.splash-img.tiny {
  max-height: 130px !important;
}
.splash-hero-wrapper {
  position: relative;
  height: 0;
}
.splash-hero-wrapper.saving {
  padding-top: min(600px, calc(581 / 548 * 100%));
}
@media (max-width: 1024px) {
  .splash-hero-wrapper.saving {
    padding-top: min(60vh, calc(581 / 548 * 100%));
  }
}
.splash-hero-wrapper.instant {
  padding-top: min(600px, calc(468 / 391 * 100%));
}
@media (max-width: 1024px) {
  .splash-hero-wrapper.instant {
    padding-top: min(60vh, calc(468 / 391 * 100%));
  }
}
.splash-hero-wrapper.cash {
  padding-top: min(600px, calc(582 / 487 * 100%));
}
@media (max-width: 1024px) {
  .splash-hero-wrapper.cash {
    padding-top: min(60vh, calc(582 / 487 * 100%));
  }
}
.splash-hero-wrapper.investing {
  padding-top: min(600px, calc(894 / 745 * 100%));
}
@media (max-width: 1024px) {
  .splash-hero-wrapper.investing {
    padding-top: min(60vh, calc(894 / 745 * 100%));
  }
}
.splash-hero-wrapper.budgeting {
  padding-top: min(600px, calc(773 / 643 * 100%));
}
@media (max-width: 1024px) {
  .splash-hero-wrapper.budgeting {
    padding-top: min(60vh, calc(773 / 643 * 100%));
  }
}
.splash-hero-wrapper.genius {
  padding-top: min(600px, calc(789 / 740 * 100%));
}
@media (max-width: 1024px) {
  .splash-hero-wrapper.genius {
    padding-top: min(60vh, calc(789 / 740 * 100%));
  }
}
.splash-hero {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  max-width: 100%;
  height: auto;
  max-height: 600px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
@media (max-width: 1024px) {
  .splash-hero {
    max-height: 60vh;
  }
}
@media (max-width: 1024px) {
  .splash-mobile-small-img {
    height: 1.5em;
  }
}
.splash-center-img {
  text-align: center;
}
.splash-center-img img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: auto;
  max-width: 80%;
  max-height: 80px;
  padding-bottom: 20px;
}
.splash-center-img img.large {
  max-height: 109px;
}
.splash-center-img p {
  max-width: 80%;
  margin: 0 auto;
}
@media (min-width: 1201px) {
  .splash-center-img h4 {
    max-width: 50%;
    margin: 0 auto;
  }
}
.splash-center-img .security-lock {
  width: 14vw;
  max-width: 60px;
}
@media (max-width: 1024px) {
  .splash-zero-dollars {
    transform: scale(0.72);
  }
}
.splash-zero-dollars .zero {
  font-size: 100px;
  font-weight: bold;
  fill: #fff;
  stroke: #333;
  stroke-width: 8px;
  paint-order: stroke fill;
}
.splash-zero-dollars .dollar {
  font-size: 38px;
  font-weight: 600;
  fill: #fff;
  stroke: #333;
  stroke-width: 8px;
  paint-order: stroke fill;
  vertical-align: middle;
}
.splash-app-feature {
  width: 100%;
  max-width: 640px;
  margin: auto;
}
@media (min-width: 769px) {
  .splash-app-feature.short {
    max-height: 550px;
    width: auto;
  }
}
@media (max-width: 1024px) {
  .splash-app-feature {
    max-width: 480px;
    margin: auto;
  }
  .splash-app-feature.full {
    max-width: 100%;
  }
}
.genius-hero-img {
  max-height: 650px;
  width: auto;
}
@media (max-width: 1024px) and (min-width: 769px) {
  .genius-hero-img {
    margin-left: 40px;
  }
}
@media (max-width: 600px) {
  .genius-hero-img {
    width: 100%;
  }
}
.genius-hero-img-container {
  width: fit-content !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.press-logos {
  justify-content: space-between;
  padding-left: 3%;
}
.press-logos img {
  width: 80%;
  padding: 10%;
}
.press-logos > span {
  display: flex;
  align-items: center;
}
.co-img img {
  width: 100%;
  height: auto;
  border-radius: 4px;
  column-gap: 35px;
}
.co-img > div:not(:last-child) {
  margin-right: 1em;
}
@media (max-width: 600px) {
  .co-img > div:not(:last-child) {
    margin-right: 0.4em;
  }
}
.co-imng-wide {
  flex: 1.25;
}
@media (max-width: 600px) {
  .company-header {
    font-size: 1.6em;
  }
}
.team-tile {
  width: 100%;
  display: block;
  border-radius: 0.222em;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.2);
  border-top: 0.333em solid #333;
  color: #333;
  padding: 3.5em 0;
}
@media (max-width: 1024px) {
  .team-tile {
    padding: 3.556em 0 3.833em 0;
  }
}
@media (max-width: 600px) {
  .team-tile {
    padding: 1.444em 0 1.667em 0;
  }
}
.team-tile:hover {
  box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.2);
}
.single-job-content {
  width: 65% !important;
}
@media (max-width: 600px) {
  .single-job-content {
    width: 80% !important;
  }
}
.single-job-header {
  margin-bottom: 3.333em;
}
.single-job-section {
  margin-top: 2.5em;
}
.single-job-section .single-job-section-header,
.single-job-section.overview-section b {
  display: block;
  margin-bottom: 0.222em;
  font-size: 1.111em;
  font-weight: 700;
  letter-spacing: 0.014em;
}
.single-job-section.overview-section b {
  margin-top: 2.5em;
}
.single-job-section .single-job-section-content ul {
  padding-inline-start: 1.2em;
}
.single-job-footer a,
.single-job-footer a:visited {
  text-decoration: underline;
  color: #333;
}
a.single-job-apply {
  display: inline-block;
  padding: 0.667em;
  min-width: 16.667em;
}
@media (max-width: 375px) {
  a.single-job-apply {
    min-width: initial;
    display: block;
  }
}
.video-image-container {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
}
.video-image-container:hover .video-play-button {
  height: 9vw;
  width: 9vw;
}
.video-image-container:hover .video-play-button i {
  font-size: 2.4vw;
}
.video-image-opacity-container {
  display: block;
  width: 100%;
}
.video-image-opacity-container:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #333;
  opacity: 0.5;
}
.video-image {
  width: 100%;
  display: block;
}
.video-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 8vw;
  width: 8vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #06f;
  background-color: #fff;
  border-radius: 50%;
}
.video-play-button i {
  margin-left: 0.7vw;
  font-size: 2vw;
}
.video-player-container {
  z-index: -1;
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -2px;
}
.video-player-container.show {
  z-index: 0;
}
.video-player {
  height: 100%;
  width: 100%;
}
.content-jobs-list {
  max-width: 760px !important;
}
.quote-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.quote-image {
  background-size: cover;
  background-position: center center;
  border-radius: 50%;
  height: 4.167em;
  width: 4.167em;
  margin-right: 1.389em;
}
.job-dropdown {
  display: inline-block;
  position: relative;
  min-width: 11.406em;
  margin-right: 0.667em;
  margin-bottom: 0.889em;
}
@media (max-width: 600px) {
  .job-dropdown {
    width: 100%;
  }
}
.job-dropdown-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  border: 1px solid #cfd1dc;
  border-radius: 0.222em;
  line-height: 1.278em;
  height: 1.278em;
  cursor: pointer;
}
.job-dropdown-header:hover i {
  font-size: 1.222em;
}
.job-dropdown-header-title {
  padding-right: 1.611em;
  color: #a2a5b2;
}
.job-dropdown-header-title.selected {
  color: initial;
}
.job-dropdown-header-caret {
  color: #a2a5b2;
  text-align: center;
}
.job-dropdown-menu {
  display: none;
  position: absolute;
  margin: 0.889em 0 0 0;
  padding: 1em 0;
  border: 1px solid #cfd1dc;
  border-radius: 0.222em;
  background: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  list-style-type: none;
  text-align: left;
  z-index: 1;
}
.job-dropdown-menu li {
  padding: 0.722em 1.056em;
  cursor: pointer;
}
.job-dropdown-menu li:hover {
  background-color: #f2f3f7;
}
@media (max-width: 600px) {
  .job-dropdown-menu {
    width: 100%;
  }
}
.job-dropdown-menu.show {
  display: block;
}
.dept-item {
  margin-top: 0px;
  margin-bottom: 60px;
}
.dept-header {
  margin-bottom: 24px;
  font-size: 1.778em;
  font-weight: 500;
}
.team-item {
  margin-top: 24px;
  margin-bottom: 60px;
}
.team-header {
  font-size: 1.111em;
  font-weight: 700;
}
.job-item {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
}
.job-header {
  width: 60%;
  padding-right: 1em;
}
.job-detail-container {
  display: flex;
  flex-wrap: wrap;
  line-height: 1.333em;
}
.job-detail {
  color: #a2a5b2;
  padding-right: 1.333em;
  font-size: 0.75em;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.job-detail:last-child {
  padding-right: 0px;
}
.job-detail.small {
  padding-right: 0.5em;
}
.job-detail.small:last-child {
  padding-right: 0px;
}
.job-action {
  width: 40%;
  text-align: right;
}
a.job-apply {
  display: inline-block;
  margin-top: 0.1em;
  padding: 0.444em 1.944em 0.5em;
  line-height: 1.111em;
}
.no-results {
  display: none;
  margin-top: 14px;
  text-align: center;
}
.no-results.show {
  display: block;
}
.no-results .no-results-message {
  color: #a2a5b2;
  margin-bottom: 1.333em;
}
.no-results .no-results-action-text {
  color: #06f;
  cursor: pointer;
}
.no-results .no-results-action-text:hover {
  font-size: 1.028em;
}
.badge-gray {
  margin-top: 10px;
  padding: 8px 16px;
  background: #f4f4f7;
  color: #a2a5b2;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  border-radius: 20px;
  display: inline-block;
  font-size: 0.75em;
}
.badge-green {
  font-size: 0.75em;
  font-weight: 600;
  color: #fff;
  text-align: center;
  padding: 8px 15px;
  background: #0fbb68;
  border-radius: 36px;
  display: inline-block;
}
@media (max-width: 1024px) {
  .badge-green {
    font-size: 12px;
    padding: 5px 12px;
  }
}
@media (max-width: 600px) {
  .badge-green {
    font-size: 10px;
    padding: 5px 12px;
  }
}
.badge-green.small {
  padding: 4px 11px;
}
.pay-whats-fair {
  border: 3px solid #333;
  box-sizing: border-box;
  border-radius: 108px;
  padding: 18px 24px;
  position: relative;
  margin-bottom: 20px;
  min-width: 230px;
}
@media (max-width: 600px) {
  .pay-whats-fair {
    width: 200px;
    padding: 20px 15px;
    border-radius: 65px;
    border: 3px solid #333;
  }
}
.main-logo {
  width: 6em;
}
.main-genius-container {
  height: 145px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 600px) {
  .main-genius-container.fair {
    width: 200px;
    margin-top: 40px;
    height: 100px;
  }
}
@media (max-width: 1024px) {
  .main-genius-container {
    height: 125px;
  }
}
@media (min-width: 1025px) and (max-width: 1570px) {
  .main-genius-content h4 {
    font-size: 18px;
  }
}
.progress-bar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 4px;
  background-color: rgba(0, 0, 0, 0);
  z-index: 1000;
  display: none;
}
.progress-bar-status {
  position: absolute;
  width: 60%;
  height: 100%;
  background-color: #000;
  transition: width 0.6s cubic-bezier(0.55, 0, 1, 0.45);
}
.sms-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 998;
  background-color: rgba(0, 0, 0, 0.3);
}
.sms-modal-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
}
.sms-modal-window {
  animation: fade-in 500ms ease;
  z-index: 999;
  width: 100%;
  max-width: 800px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4.7924px 14.0741px rgba(0, 0, 0, 0.18);
  border-radius: 14px;
  padding: 5.5rem 1rem 4rem 1rem;
  position: relative;
}
.sms-modal-window hr {
  width: 100%;
  border: 0.5px solid #cfd1dc;
  margin-top: 2rem;
  margin-bottom: 3rem;
}
.sms-modal-close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.sms-modal-content-container {
  width: 595px;
  display: flex;
  flex-direction: column;
  margin: auto;
}
.sms-modal-content {
  display: flex;
  margin-left: -15px;
}
.sms-modal-content > * {
  flex: 1 1 0;
  margin: auto;
}
.sms-modal-qr {
  width: 240px;
  margin-right: 45px;
}
.sms-modal-app-buttons {
  display: flex;
  margin: auto;
}
.sms-modal-app-buttons img:first-child {
  margin-right: 12px;
}
.sms-modal-app-buttons img {
  width: 142px;
}
.fade-out {
  transition: opacity 250ms;
  opacity: 0;
}
.fade-in-wrap {
  animation: fade-in 500ms ease;
}
.apply-wrap.protect-waitlist .apply-header {
  max-width: 800px;
}
.card-grid {
  width: 100%;
  display: grid;
  grid-gap: 2em;
  grid-template-columns: repeat(auto-fill, minmax(28%, 1fr));
}
@media (max-width: 1024px) {
  .card-grid {
    grid-gap: 1.2em;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  }
}
@media (max-width: 600px) {
  .card-grid {
    grid-gap: 0.8em;
    grid-template-columns: 0.5em repeat(auto-fill, minmax(35%, 1fr)) 0.5em;
  }
}
@media (max-width: 375px) {
  .card-grid {
    grid-gap: 0.7em;
    grid-template-columns: 0.6em repeat(auto-fill, minmax(40%, 1fr)) 0.6em;
  }
}
.card-grid-row {
  position: relative;
  display: grid;
  grid-column: 1/-1;
  grid-gap: 2em;
  grid-template-columns: repeat(auto-fill, minmax(28%, 1fr));
  grid-auto-flow: column;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.card-grid-row::-webkit-scrollbar {
  display: none;
}
@media (max-width: 1024px) {
  .card-grid-row {
    grid-gap: 1.3em;
    grid-template-columns: repeat(minmax(30%, 1fr));
  }
}
@media (max-width: 600px) {
  .card-grid-row {
    grid-gap: 0.7em;
    grid-template-columns:
      0.6em minmax(42%, 1fr) minmax(42%, 1fr) minmax(42%, 1fr)
      0.6em;
  }
}
.pseudo-card {
  display: none;
}
@media (max-width: 600px) {
  .pseudo-card {
    display: block;
  }
}
.aspect-ratio-container {
  padding-top: 145%;
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  border-radius: 8px;
}
@media (max-width: 600px) {
  .aspect-ratio-container {
    padding-top: 180%;
  }
}
.card-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.blog-category-nav {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 3em 0 4em 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.blog-category-nav::-webkit-scrollbar {
  display: none;
}
@media (max-width: 1024px) {
  .blog-category-nav {
    float: left;
    display: inherit;
    cursor: pointer;
    margin: 0 auto;
    text-align: center;
    overflow: visible;
    overflow-y: scroll;
    white-space: nowrap;
  }
}
.blog-category-nav .blog-category {
  color: #333;
  padding: 1em 1.6vw;
}
.blog-category-nav .blog-category.selected,
.blog-category-nav .blog-category:hover {
  color: #0073dc;
}
@media (max-width: 1024px) {
  .blog-category-nav .blog-category {
    display: inline-block;
    color: #6b6e7c;
    background-color: #f2f3f7;
    border-radius: 8px;
    margin-right: 8px;
    padding: 0.5em 1em;
    text-decoration: none;
  }
  .blog-category-nav .blog-category:first-child {
    margin-left: 1.3em;
  }
  .blog-category-nav .blog-category:last-child {
    margin-right: 1.3em;
  }
  .blog-category-nav .blog-category.selected,
  .blog-category-nav .blog-category:hover {
    background-color: #06f;
    color: #fff;
  }
}
.blog-default-text {
  color: #a2a5b2;
  font-size: 130%;
}
.page-nav-text {
  align-self: center;
  color: #6b6e7c;
  font-size: 0.88em;
  margin: 0 0.88em;
}
.page-nav-arrow {
  cursor: pointer;
  border-radius: 50%;
  color: #6b6e7c;
  border: 2px solid #6b6e7c;
  display: table-cell;
  vertical-align: middle;
  width: 1.6em;
  height: 1.6em;
}
.page-nav-arrow:visited {
  color: #6b6e7c;
}
.page-nav-arrow i {
  width: 100%;
  text-align: center;
}
.page-nav-arrow.disabled {
  opacity: 0.4;
  cursor: auto;
}
.blog-card-blur-mask {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  background-position: center center;
  background-size: cover;
  filter: url("#blur");
  mask: url("#mask-linear");
  -webkit-mask: -webkit-linear-gradient(transparent 50%, white 80%, white 100%);
  -webkit-mask: linear-gradient(transparent 50%, white 80%, white 100%);
}
.blog-card {
  display: block;
  position: absolute;
  background-size: cover;
  background-position: center center;
  height: 100%;
  width: 100%;
}
.blog-card .blog-card-overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (max-width: 600px) {
  .blog-card {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
@media (max-width: 1024px) {
  .blog-card {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
.blog-img {
  border-radius: 8px;
  height: auto;
  width: 100%;
}
.blog-title-wrapper {
  margin-bottom: 1rem;
}
.blog-title-wrapper h1 {
  font-size: 3.8em;
  margin-bottom: 0.2em;
}
.blog-title-wrapper h2 {
  font-size: 1.8em;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}
@media (max-width: 1024px) {
  .blog-title-wrapper {
    padding: 0;
  }
}
@media (max-width: 600px) {
  .blog-title-wrapper {
    padding: 0;
  }
  .blog-title-wrapper h1 {
    font-size: 2em;
  }
  .blog-title-wrapper h2 {
    font-size: 1em;
  }
}
.blog-content {
  padding: 2em 8em 6em;
  max-width: 800px;
  margin: 0 auto;
}
.blog-content h3 {
  font-size: 1.1em;
}
.blog-content h3:not(:first-child) {
  margin-top: 3rem;
}
.blog-content h3:first-child {
  margin-top: 1rem;
}
@media (max-width: 600px) {
  .blog-content {
    padding: 2em 0 6em;
    width: 100%;
  }
  .blog-content h1 {
    font-size: 2em;
  }
}
@media (max-width: 1024px) {
  .blog-content {
    width: 100%;
    padding: 2em 0 6em;
  }
}
.blog-content .article-content {
  position: relative;
}
.blog-content .article-content p {
  color: #53545a;
}
.blog-content .article-content ul {
  list-style: none;
}
.blog-content .article-content ul,
.blog-content .article-content ol li {
  color: #53545a;
}
.blog-content .article-content ul li::before {
  content: "â€¢";
  color: #a2a5b2;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
.blog-content .article-content a,
.blog-content .article-content a:active,
.blog-content .article-content a:visited,
.blog-content .article-content a:focus {
  color: #53545a;
  text-decoration: underline;
}
.blog-content .article-content a:hover {
  color: #06f;
}
.blog-content .article-content img {
  border-radius: 8px;
  height: auto;
  width: 100%;
}
.divider {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  max-width: 100%;
  width: 100%;
  border: 1px solid #f4f4f7;
}
.blog-recent-articles {
  padding-top: 8em;
}
.image-spacing {
  width: 100%;
}
.image-spacing:nth-child(2) {
  margin-left: 1em;
  margin-right: 1em;
}
@media (max-width: 600px) {
  .image-spacing {
    margin-bottom: 1em;
  }
  .image-spacing:nth-child(2) {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (max-width: 1024px) {
  .image-spacing {
    margin-bottom: 1em;
  }
  .image-spacing:nth-child(2) {
    margin-left: 0;
    margin-right: 0;
  }
}
.image-content {
  bottom: 0;
  left: 0;
  z-index: 2;
  position: absolute;
  color: #fff;
  padding: 1.8em;
}
.image-content h3 {
  font-weight: 600;
  font-size: 1.5em;
}
@media (max-width: 1024px) {
  .image-content {
    padding: 1em;
  }
  .image-content h3 {
    font-size: 1.25em;
  }
}
@media (max-width: 600px) {
  .image-content {
    padding: 0.85em;
  }
  .image-content h3 {
    font-size: 1.1em;
  }
}
@media (max-width: 375px) {
  .image-content {
    padding: 0.65em;
  }
  .image-content h3 {
    font-size: 0.9em;
  }
}
.published-date {
  padding-top: 1.3em;
  font-size: 0.75em;
  font-weight: 600;
  opacity: 0.75;
}
.published-date span.normal {
  color: #6b6e7c;
}
.published-date span.image {
  color: #fff;
  opacity: 0.75;
}
@media (max-width: 600px) {
  .published-date {
    padding-top: 0.5em;
    font-size: 0.7em;
  }
}
@media (max-width: 375px) {
  .published-date {
    font-size: 0.6em;
  }
}
.media-caption {
  padding-top: 1em;
  padding-bottom: 2em;
  max-width: 800px;
  font-style: italic;
  font-family: "Work sans", sans-serif;
  font-weight: 400;
}
.media-caption p {
  color: #a2a5b2;
}
.media-caption a,
.media-caption a:active,
.media-caption a:visited,
.media-caption a:focus {
  color: #a2a5b2;
  text-decoration: underline;
}
.media-caption a:hover {
  color: #06f;
}
.all-posts-button {
  letter-spacing: 0.1em;
}
.post-banner-header {
  font-size: 2.7em;
}
@media (max-width: 600px) {
  .post-banner-header {
    font-size: 2em;
  }
}
@media (max-width: 375px) {
  .post-banner-header {
    font-size: 1.8em;
  }
}
.post-article-banner .app-buttons,
.post-home-banner .app-buttons {
  padding-top: 0.25em;
}
@media (max-width: 1024px) {
  .post-article-banner .app-buttons,
  .post-home-banner .app-buttons {
    padding-top: 1em;
  }
}
.post-home-banner .text-app .sub-title {
  font-size: 0.8em;
}
.post-home-banner {
  width: 50%;
}
.post-home-banner p {
  font-size: 1.2em;
}
@media (max-width: 1024px) {
  .post-home-banner {
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 600px) {
  .post-home-banner p {
    font-size: 1em;
  }
}
.post-banner-image {
  width: 65%;
  text-align: center;
}
@media (max-width: 1024px) {
  .post-banner-image {
    width: 100%;
  }
}
.post-article-banner {
  border: thin solid #f2f3f7;
  border-radius: 18px;
  text-align: center;
  width: 100%;
}
@media (max-width: 600px) {
  .post-article-banner {
    left: 50%;
    width: 100vw;
    margin-left: -50vw;
    position: relative;
    border-radius: 0;
  }
}
.content-fade-out {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 3rem 0;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.0001) 0%,
    rgba(247, 247, 249, 0.0001) 0%,
    rgba(247, 247, 249, 0.0001) 0.01%,
    #ffffff 80.47%
  );
}
.article-lock {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  background-color: #a2a5b2;
  border-radius: 50%;
  width: 1.8em;
  height: 1.8em;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.9;
  margin: 0.8em 0.8em 0 0;
}
.article-lock i {
  color: #333;
  font-size: 1.3em;
}
@media (max-width: 1200px) {
  .article-lock {
    width: 1.5em;
    height: 1.5em;
    margin: 0.5em 0.5em 0 0;
  }
  .article-lock i {
    font-size: 1em;
  }
}
@media (max-width: 600px) {
  .article-lock {
    width: 1.5em;
    height: 1.5em;
    margin: 0.5em 0.5em 0 0;
  }
  .article-lock i {
    font-size: 1em;
  }
}
@media (max-width: 375px) {
  .article-lock {
    width: 1.1em;
    height: 1.1em;
    margin: 0.5em 0.5em 0 0;
  }
  .article-lock i {
    font-size: 0.7em;
  }
}
.social-nav-toggle {
  pointer-events: auto;
  display: none;
  height: 55px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #fff;
  vertical-align: middle;
  cursor: pointer;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
}
@media (max-width: 600px) {
  .social-nav-toggle {
    display: inline-flex;
    justify-content: center;
  }
}
.social-nav-toggle img {
  padding: 0 12px;
}
.social-nav {
  width: 100%;
  pointer-events: none;
  z-index: 2;
  left: 0;
  position: fixed;
}
.social-nav .social-nav-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}
.social-nav .social-nav-wrapper ul {
  pointer-events: auto;
  display: inline-flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  list-style: none;
  width: 55px;
  padding: 10px 0;
  border-radius: 8px;
  background-color: #fff;
  position: relative;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
}
@media (max-width: 600px) {
  .social-nav .social-nav-wrapper ul {
    display: none;
    flex-direction: row;
    width: auto;
    height: 55px;
    margin: 0;
    padding: 0 10px;
    margin-left: 15px;
  }
  .social-nav .social-nav-wrapper ul::before {
    content: "";
    position: absolute;
    left: -5px;
    top: 23px;
    width: 11px;
    height: 11px;
    background-color: #fff;
    box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: -2px 2px 3.5px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: -2px 2px 3.5px rgba(0, 0, 0, 0.1);
    transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}
.social-nav .social-nav-wrapper ul li {
  display: block;
  padding: 11px;
}
.social-nav .social-nav-wrapper ul li i {
  font-size: 13px;
  padding: 10px;
}
.social-nav.visible > .social-nav-wrapper > ul {
  display: inline-flex;
}
.social-nav .circle {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
}
@media (max-width: 600px) {
  .social-nav .circle {
    min-width: 33px;
    min-height: 33px;
  }
}
.social-nav .circle.facebook {
  background-color: #4267b2;
}
.social-nav .circle.pinterest {
  background-color: #bd081c;
}
.social-nav .circle.twitter {
  background-color: #38a1f3;
}
.social-nav .circle.email {
  background-color: #a2a5b2;
}
.hero-spacer {
  padding-top: 3.5rem;
}
@media (max-width: 1024px) {
  .hero-spacer {
    padding-top: 2rem;
  }
}
.main-container {
  background-color: #f8f8fa;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  padding-top: 3.5rem;
  margin-left: auto;
}
@media (min-width: 769px) {
  .main-container > * {
    margin-top: 3.5rem;
  }
  .main-container > :last-child {
    padding-bottom: 11rem;
  }
}
@media (max-width: 1300px) {
  .main-container {
    padding-right: 5vw;
    padding-left: 5vw;
  }
}
@media (max-width: 768px) {
  .main-container {
    display: grid;
    padding-top: 2rem;
    grid-auto-rows: 1fr;
    grid-row-gap: 2rem;
  }
}
.main-section {
  width: 100%;
  max-width: 1220px;
  margin-right: auto;
  margin-left: auto;
}
@media (max-width: 1024px) {
  .main-section {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (min-width: 769px) {
  .main-section > :not(:first-child) {
    margin-left: 2rem;
  }
}
.main-hero-header {
  max-width: 575px;
}
@media (max-width: 1040px) {
  .main-hero-header {
    font-size: 3.4rem;
    max-width: 470px;
  }
}
@media (max-width: 890px) {
  .main-hero-header {
    font-size: 3rem;
    max-width: 390px;
  }
}
@media (max-width: 768px) {
  .main-hero-header {
    max-width: 380px;
    text-align: center;
    font-size: 2rem;
    padding: 0 12px;
  }
  .main-hero-header span {
    display: block;
  }
}
.fade-in {
  animation: fade-in 1s cubic-bezier(0.32, 0, 0.67, 0);
}
.main-hero {
  position: relative;
  height: calc(100vh - 90px);
  display: flex;
  background-color: transparent;
  max-width: 1220px;
  flex-direction: column;
  align-items: center;
  min-height: 460px;
}
.main-hero-top {
  flex: 0 0 auto;
  order: 1;
}
.main-hero-center {
  flex: 1 1 auto;
  order: 2;
  height: 100%;
  width: 100%;
  display: flex;
  padding-bottom: 10px;
}
.main-hero-bottom {
  flex: 0 0 auto;
  width: 100%;
  padding-bottom: 64px;
  order: 3;
}
@media (min-width: 769px) {
  .main-hero {
    min-height: 480px;
    flex-wrap: wrap;
  }
  .main-hero-top {
    height: 50%;
    width: 55%;
    text-align: left;
    padding: 0;
    display: flex;
    align-items: flex-end;
  }
  .main-hero-top > * {
    padding-left: 5vw;
  }
  .main-hero-center {
    height: 0;
    width: 50%;
    margin-left: 30px !important;
    padding-bottom: 50px;
    order: 3;
  }
  .main-hero-bottom {
    height: 50%;
    width: 55%;
    order: 2;
    padding-bottom: 0;
  }
  .main-hero-bottom > * {
    padding-left: 5vw;
  }
}
@media (min-width: 769px) and (min-width: 769px) {
  .main-hero > :not(:first-child) {
    margin-left: 0;
  }
}
.main-hero-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  max-width: 800px;
}
@media (min-width: 1025px) {
  .main-hero-content {
    flex: 1;
  }
}
.main-hero-content h1 {
  margin-top: 0;
}
@media (max-width: 1024px) {
  .main-hero-content {
    text-align: center;
    margin-bottom: 0;
    padding-left: 0;
  }
  .main-hero-content h1 {
    padding-left: 24px;
    padding-right: 24px;
  }
  .main-hero-content button {
    max-width: 560px;
  }
}
@media (max-width: 320px) {
  .main-hero-content h1 {
    font-size: 2.1rem;
  }
}
@media (max-width: 1024px) and (max-height: 750px) {
  .main-hero-content h1 {
    font-size: 2.2rem;
  }
}
@media (max-width: 768px) {
  .main-hero-lottie svg {
    margin: auto;
    height: 45vh !important;
    transform: translate3d(min(15vw, 60px), 0px, 0px) scale(1.1) !important;
  }
}
@media (max-width: 768px) and (max-height: 650px) {
  .main-hero-lottie svg {
    transform: translate3d(min(15vw, 50px), 0px, 0px) scale(1.1) !important;
    height: 40vh !important;
  }
}
.round-card {
  position: relative;
  display: flex;
}
.round-card-img-container {
  display: flex;
  text-align: center;
  flex: 1 1 0;
}
@media (max-width: 768px) {
  .round-card-img-container.cash {
    padding-bottom: 16px;
  }
}
.round-card:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 40px;
  background-color: #fff;
  top: 0;
  left: 0;
  z-index: 1;
}
@media (max-width: 768px) {
  .round-card:after {
    border-radius: 16px;
  }
}
@media (max-width: 768px) {
  .round-card {
    height: 100%;
  }
}
.round-card-inner {
  padding: 2.5rem 4.44rem;
  position: relative;
  flex: 1 1 0;
  display: inline-flex;
  z-index: 2;
}
.round-card-inner.saving {
  padding: 2.5rem 2.44rem;
}
@media (max-width: 600px) {
  .round-card-inner.genius {
    padding: 1.5rem 1.5rem;
  }
}
@media (max-width: 1024px) {
  .round-card-inner {
    padding: 2.5rem 2.5rem;
  }
}
@media (max-width: 815px) {
  .round-card-inner {
    padding: 2.5rem 2rem;
  }
}
@media (max-width: 600px) {
  .round-card-inner {
    padding: 2.5rem 1.5rem;
  }
}
@media (max-width: 768px) {
  .round-card-inner {
    padding-bottom: 0;
    flex-direction: column;
  }
  .round-card-inner .mobile-only {
    flex-grow: 1;
    display: flex;
  }
  .round-card-inner .mobile-only img {
    margin: auto;
  }
}
@media (min-width: 769px) {
  .round-card-inner > * {
    flex: 1 1 0;
    margin: auto;
  }
}
.round-card.yellow:after {
  background-color: #e77d24;
}
.round-card.yellow-gradient:after {
  background: linear-gradient(43.04deg, #fec14c -9.74%, #e77d24 98.89%);
}
.round-card.green:after {
  background-color: #64f19c;
}
.round-card.blue:after {
  background-color: #8bd5ff;
}
.round-card.black {
  color: #fff;
}
.round-card.black .circle-plus line,
.round-card.black .circle-plus path {
  stroke: #eeae01;
}
.round-card.black a {
  color: #fff;
  text-decoration: underline;
}
.round-card.black a:hover {
  color: #06f;
}
.round-card.black:after {
  background-color: #000;
}
.round-card.square {
  flex: 1 1 0;
}

.round-card-gold-gradient-text {
  margin-top: 2rem;
  background-color: #eeae01;
  background-image: linear-gradient(112.55deg, #ffc700 22.14%, #a03a01 116.27%);
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
}
.round-card-content {
  display: flex;
  flex-direction: column;
}
@media (min-width: 769px) {
  .round-card-content {
    flex: 1 1 0;
    margin: auto;
  }
  .round-card-content > :not(:first-child):not(h2) {
    margin-top: 1rem;
    padding-bottom: 2rem;
  }
}
@media (max-width: 768px) {
  .round-card-content h2 {
    font-size: 1.75rem;
  }
}
.round-card-content p {
  font-size: 1.5rem;
}
@media (max-width: 768px) {
  .round-card-content p {
    font-weight: 500;
    font-size: 1.15rem;
  }
}
@media (max-width: 768px) {
  .bottom-p {
    padding-bottom: 2rem;
  }
}
@media (min-width: 769px) {
  .budget-pic {
    padding: 0 16px;
  }
}
@media (max-width: 768px) {
  .investing-img {
    width: 105%;
    margin-left: -2.5%;
  }
}
.reveal .reveal-delay,
.reveal .reveal-standard,
.reveal:after {
  transform: translate3d(0, 250px, 0);
  opacity: 0;
}
.reveal.active .reveal-standard,
.reveal.active:after {
  transition: transform 1s ease-in-out, opacity 1.2s;
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.reveal.active .reveal-delay {
  transition: transform 1s ease-in-out 150ms,
    opacity 1.2s cubic-bezier(0.32, 0, 0.67, 0) 150ms;
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.reveal-delay {
  position: relative;
  z-index: 2;
}

@media (hover: hover) {
  .img-card:has(+ .card-hover:hover) .img-scale {
    transform: scale(1.025);
  }
}
@media (hover: hover) {
  .round-card:has(+ .card-hover:hover) .img-scale {
    transform: scale(1.05);
  }
}
.card-hover:hover .circle-plus svg {
  fill: #333;
}
.card-hover:hover .circle-plus line {
  stroke: #fff;
}
@media (hover: hover) {
  .card-hover:hover .img-scale {
    transform: scale(1.05);
  }
}
.card-hover.yellow:hover .circle-plus svg {
  fill: #333;
}
.card-hover.yellow:hover .circle-plus line {
  stroke: #e77d24;
}
.card-hover.green:hover .circle-plus svg {
  fill: #333;
}
.card-hover.green:hover .circle-plus line {
  stroke: #64f19c;
}
.card-hover.blue:hover .circle-plus svg {
  fill: #333;
}
.card-hover.blue:hover .circle-plus line {
  stroke: #8bd5ff;
}
.card-hover.black:hover .circle-plus svg {
  fill: #eeae01;
}
.card-hover.black:hover .circle-plus line {
  stroke: #000;
}
.serious-security {
  display: flex;
  flex-direction: column;
  padding: 5rem 4.44rem;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1024px) {
  .serious-security {
    padding: 5rem 2.5rem;
  }
}
@media (max-width: 815px) {
  .serious-security {
    padding: 5rem 1.5rem;
  }
}
.serious-security-header {
  text-align: center;
  flex: 1;
}
.serious-security-table {
  flex: 3;
}
.serious-security-icon {
  margin: auto;
  width: 80px;
}
@media (max-width: 600px) {
  .serious-security-icon {
    width: 40px;
  }
}
.serious-security h2 {
  margin: 1.5rem 0 5.5rem 0;
  text-align: center;
}
@media (max-width: 768px) {
  .serious-security h2 {
    margin: 1.5rem 0 2.5rem 0;
  }
}
.serious-security h4 {
  font-size: 2rem;
}
.serious-security h4 span {
  display: block;
}
@media (max-width: 768px) {
  .serious-security h4 {
    font-size: 1.5rem;
    margin: 0;
  }
}
@media (max-width: 600px) {
  .serious-security h4 {
    font-size: 1rem;
  }
}
.serious-security p {
  margin-top: 2rem;
}
@media (max-width: 768px) {
  .serious-security p {
    margin: 0;
    flex: 2 1 0;
  }
}
@media (max-width: 600px) {
  .serious-security p {
    font-size: 16px;
    line-height: 1.4;
  }
}
@media (max-width: 768px) {
  .serious-security-item {
    display: flex;
    flex-direction: row;
  }
  .serious-security-item > * {
    flex: 1 1 0;
    margin: auto;
  }
}
.helped-members {
  margin-top: 16.5rem;
  max-width: 1220px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 768px) {
  .helped-members {
    margin-top: 10rem;
  }
}
.helped-members img.large {
  max-height: 88px;
}
.img-card {
  flex: 1 1 0;
}
.img-card img {
  width: 100%;
}
.circle-plus {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0 50px 50px 0;
  z-index: 2;
  height: 33px;
}
@media (max-width: 1024px) {
  .circle-plus {
    margin: 0 25px 25px 0;
  }
}
.main-disclaimer {
  color: #747680;
  font-weight: 600;
  text-align: center;
  font-size: 14px;
  position: absolute;
  bottom: 30px;
}
@media (max-width: 768px) {
  .main-disclaimer {
    font-size: 12px;
    width: 90%;
    font-weight: 600;
    bottom: 12px;
    line-height: 1.2;
  }
}
.main-button-wrapper {
  display: none;
  margin-top: 1rem;
}
.main-button-wrapper .button {
  font-size: 20px;
  padding: 20px 40px;
  border-radius: 84px;
}
@media (max-width: 768px) {
  .main-button-wrapper .button {
    padding: 0.7em 0;
    width: 70%;
    text-align: center;
    display: block;
    margin: 0 auto;
    font-weight: 700;
  }
}
@media (max-width: 1024px) {
  .main-button-wrapper {
    margin-top: 0.5rem;
  }
}
@media (min-width: 769px) {
  .mobile-only {
    opacity: 0 !important;
    position: absolute !important;
    transition-property: none;
  }
}
@media (max-width: 768px) {
  .desktop-only {
    opacity: 0 !important;
    position: absolute !important;
    transition-property: none;
  }
}
.smart-banner-wrapper {
  display: none;
  position: fixed;
  justify-content: center;
  bottom: 24px;
  width: 100vw;
  z-index: 499;
}
@keyframes banner-slide {
  from {
    transform: translate3d(0, 120px, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
.smart-banner-animation {
  animation: banner-slide 500ms cubic-bezier(0.34, 1.56, 0.64, 1);
}
.smart-banner {
  width: 100%;
  max-width: 350px;
  background-color: #06f;
  padding: 14px;
  display: flex;
  margin: 0 30px;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  min-width: 225px;
}
@media (max-width: 350px) {
  .smart-banner {
    padding: 14px 6px;
  }
}
.smart-banner .disclosures {
  color: #fff;
  font-size: 1em;
  font-weight: 500;
}
@media (max-width: 395px) {
  .smart-banner .disclosures {
    font-size: 0.75em;
  }
}
.smart-banner-content {
  display: flex;
}
.smart-banner-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.5em;
  color: #fff;
}
.smart-banner-text p {
  margin: 0;
}
.smart-banner-text-header {
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 5px;
  line-height: 14px;
}
.smart-banner-button .button {
  background-color: #fff;
  padding: 13px;
  color: #06f !important;
  cursor: pointer;
  font-weight: 700;
  border-radius: 10em;
  white-space: nowrap;
}
.about-header h1 {
  margin: 0 auto;
  width: 90%;
}
@media (max-width: 1410px) {
  .about-header h1 {
    width: 100%;
  }
}
.about-story-img {
  display: block;
  margin: 0 auto;
  max-width: 394px;
}
@media (max-width: 1024px) {
  .about-story-img {
    width: 100%;
  }
}
.about-story-content {
  max-width: 829px;
}
.about-human-img {
  width: 656px;
}
.about-header-divider {
  padding: 6em 0;
}
@media (max-width: 1024px) {
  .about-header-divider {
    padding: 3em 0;
  }
}
.about-careers-divider {
  padding: 150px 0;
}
@media (max-width: 1024px) {
  .about-careers-divider {
    padding: 2em 0;
  }
}
.about-side-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: auto !important;
  max-height: 671px !important;
}
@media (max-width: 1024px) {
  .about-our-values {
    padding-bottom: 64px;
  }
}
.about-footer-spacer {
  padding-top: 236px;
}
@media (max-width: 1024px) {
  .about-footer-spacer {
    padding-top: 110px;
  }
}
@media (max-width: 600px) {
  .about-footer-spacer {
    padding-top: 105px;
  }
}
.script {
  font-family: "AlbertScript", "Work sans", sans-serif;
  font-weight: 600;
}
.logo-wrapper {
  margin-top: 36px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo-wrapper img {
  height: 20px;
}
.content {
  width: 90%;
  max-width: 1250px;
  margin: 0 auto;
}
@media (max-width: 1002px) {
  .justify-lg-start {
    justify-content: flex-start;
  }
  .content {
    width: 70%;
  }
}
@media (min-width: 1002px) {
  .justify-lg-start {
    justify-content: flex-start !important;
  }
}
@media (max-width: 600px) {
  .content {
    width: 90%;
  }
}
.content-full {
  width: 100%;
}
.content-expand {
  width: 70%;
  max-width: 1250px;
  margin: 0 auto;
}
@media (max-width: 600px) {
  .content-expand {
    width: 100%;
    max-width: 100%;
  }
}
.content-narrow-expand {
  width: 50%;
  max-width: 1000px;
  margin: 0 auto;
}
@media (max-width: 1200px) {
  .content-narrow-expand {
    width: 100%;
    max-width: 100%;
  }
}
.right {
  float: right;
}
.left {
  float: left;
}
.center-margin {
  margin: auto;
}
.center-horizontal {
  margin: auto 0;
}
.flow-root {
  display: flow-root;
}
.flex {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-stack {
  flex-wrap: nowrap;
  flex-direction: row;
}
@media (max-width: 1024px) {
  .flex-stack {
    flex-wrap: wrap;
    flex-direction: column;
  }
}
.flex-stack-small {
  flex-wrap: nowrap;
  flex-direction: row;
}
@media (max-width: 768px) {
  .flex-stack-small {
    flex-wrap: wrap;
    flex-direction: column;
  }
}
.flex-stack-reverse {
  flex-wrap: nowrap;
  flex-direction: row;
}
@media (max-width: 1024px) {
  .flex-stack-reverse {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
}
.flex-stack-reverse-small {
  flex-wrap: nowrap;
  flex-direction: row;
}
@media (max-width: 768px) {
  .flex-stack-reverse-small {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
}
.flex-unstack {
  flex-wrap: wrap;
  flex-direction: column;
}
@media (max-width: 1024px) {
  .flex-unstack {
    flex-wrap: nowrap;
    flex-direction: row;
  }
}
.flex-unstack-small {
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
@media (max-width: 768px) {
  .flex-unstack-small {
    flex-wrap: nowrap;
    flex-direction: row;
  }
}
.flex-center-h {
  justify-content: center;
  align-self: center;
  align-content: center;
}
.flex-start-h {
  justify-content: center;
  align-self: flex-start;
}
.flex-left-center-h {
  justify-content: flex-start;
  align-self: center;
}
@media (max-width: 1024px) {
  .flex-left-center-h {
    justify-content: center;
  }
}
.flex-space-around-h {
  justify-content: space-around;
}
@media (max-width: 1024px) {
  .flex-space-around-h {
    justify-content: flex-start;
  }
}
.flex-space-between-h {
  justify-content: space-between;
}
@media (max-width: 1024px) {
  .flex-space-between-h {
    justify-content: flex-start;
  }
}
.flex-grow-1 {
  flex-grow: 1;
}
@media (max-width: 1024px) {
  .flex-basis-60-mobile {
    flex-basis: 60%;
  }
}
.flex-align-right {
  margin-left: auto;
}
.col-1 {
  width: 100%;
  margin: 0 auto;
}
.col-1-center {
  width: 75%;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  .col-1-center {
    width: 100%;
  }
}
.col-1-center-wide {
  width: 77%;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  .col-1-center-wide {
    width: 100%;
  }
}
.col-2 {
  width: calc(50% - 4em);
  padding: 0 4em 0 0;
  float: left;
}
.col-2-flex {
  width: calc(50% - 4em);
  float: left;
}
@media (max-width: 1024px) {
  .col-2-flex {
    width: 100%;
    padding: 0;
  }
}
.col-2-flex-wide {
  width: calc(55% - 4em);
  float: left;
}
@media (max-width: 1024px) {
  .col-2-flex-wide {
    width: 100%;
    padding: 0;
  }
}
.col-2-flex-2 {
  width: calc(50% - 4em);
  float: left;
}
@media (max-width: 1024px) {
  .col-2-flex-2 {
    width: auto;
    padding: 0;
  }
}
.col-2:nth-child(even),
.col-2-flex:nth-child(even) {
  padding: 0 0 0 4em;
}
@media (max-width: 1024px) {
  .col-2:nth-child(even),
  .col-2-flex:nth-child(even) {
    width: 100%;
    padding: 0;
  }
}
.col-3 {
  width: calc(38% - 6em);
  float: left;
}
@media (max-width: 1024px) {
  .col-3 {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 0 0 4em 0;
    float: none;
  }
  .col-3.small-mobile-padding {
    padding-bottom: 1em;
  }
}
.col-3-2 {
  width: calc(33.33% - 6em);
  padding: 0 3em 3em 3em;
  float: left;
}
@media (max-width: 1024px) {
  .col-3-2 {
    width: calc(50% - 2em);
    max-width: 500px;
    margin: 0 auto;
    padding: 0 1em 3em 1em;
    float: left;
  }
}
.col-3-2-1 {
  width: calc(33.33% - 6em);
  padding: 0 3em 3em 3em;
  float: left;
}
@media (max-width: 1024px) {
  .col-3-2-1 {
    width: calc(50% - 2em);
    max-width: 500px;
    margin: 0 auto;
    padding: 0 1em 3em 1em;
    float: left;
  }
}
@media (max-width: 768px) {
  .col-3-2-1 {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 0 0 1em 0;
  }
}
.col-3-full {
  width: 33.3%;
  padding: 0 1.5em;
}
@media (max-width: 1024px) {
  .col-3-full {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 0 0 1em 0;
  }
}
.col-3-full-small {
  width: 100%;
  padding: 0 1em 3em 1em;
}
@media (max-width: 768px) {
  .col-3-full-small {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 0 0 1em 0;
  }
}
.col-4,
.col-4-full {
  width: calc(25% - 2em);
  padding: 0 2.6em 0 0;
  float: left;
}
@media (max-width: 1024px) {
  .col-4,
  .col-4-full {
    width: calc(50% - 4em);
    padding: 0 4em 2.5em 0;
  }
}
@media (max-width: 600px) {
  .col-4,
  .col-4-full {
    width: 100%;
    padding: 0 0 2.5em 0;
  }
}
@media (max-width: 1024px) {
  .col-4-full {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 0 0 4em 0;
    float: none;
  }
}
.col-4-team-tile {
  width: calc(25% - 1.8em);
  margin: 0 0.883em 1.667em 0.883em;
  float: left;
}
@media (max-width: 1024px) {
  .col-4-team-tile {
    width: calc(50% - 1.5em);
    margin: 0 0.75em 1.111em 0.75em;
  }
}
@media (max-width: 600px) {
  .col-4-team-tile {
    width: 100%;
    margin: 0 0 1.111em 0;
  }
}
.col-last {
  padding: 0;
}
@media (min-width: 601px) {
  .img-grid p:not(.p-callout) {
    font-size: 16px;
  }
}
.img-grid p {
  margin-top: 10px;
  margin-bottom: 10px;
}
.img-grid img {
  width: 100%;
  max-width: 150px;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  .img-grid img {
    width: 25vw;
    max-width: 120px;
  }
}
.img-grid .col-img {
  width: 100%;
  max-width: 150px;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  .img-grid .col-img {
    width: calc(40% - 6em);
    padding: 0 6em 0 0;
  }
}
.img-grid .col-text {
  width: 100%;
}
@media (max-width: 1024px) {
  .img-grid .col-text {
    width: calc(60% - 6em);
    padding: 0 0 0 6em;
  }
}
@media (max-width: 768px) {
  .img-grid-small {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
}
.img-grid-small .col-text {
  width: 100%;
}
@media (max-width: 1024px) {
  .img-grid-small .col-text {
    width: 85%;
  }
}
@media (max-width: 768px) {
  .img-grid-small .col-text {
    width: calc(60% - 1em);
    padding: 0 0 0 1em;
  }
}
.table-responsive {
  min-height: 0.5em;
  overflow-x: auto;
}
.table-responsive .small-col {
  width: 20%;
}
table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  text-align: left;
}
td,
th {
  padding: 0;
}
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #cfd1dc;
}
@media (min-width: 1025px) {
  .table > tbody > tr > td.sutton-table-row,
  .table > tbody > tr > th.sutton-table-row,
  .table > tfoot > tr > td.sutton-table-row,
  .table > tfoot > tr > th.sutton-table-row,
  .table > thead > tr > td.sutton-table-row,
  .table > thead > tr > th.sutton-table-row {
    padding-left: 30px;
  }
}
.table > thead > tr > td,
.table > thead > tr > th {
  border-top: 0px solid #cfd1dc;
}
.table > tfoot {
  border-top: 2px solid #111;
  border-bottom: 1px solid #cfd1dc;
}
.light-border-container {
  border: 1px solid #cfd1dc;
}
.padding-container {
  padding: 30px;
}
@media (max-width: 1024px) {
  .padding-container {
    padding: 10px;
  }
}
.hero-img {
  width: 100%;
  max-width: 100%;
}
@media (max-width: 1024px) {
  .hero-img-mobile {
    width: 100%;
    max-width: 100%;
  }
}
.hero-img-small {
  width: 80%;
  max-width: 80%;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  .hero-img-small {
    width: 100%;
    max-width: 100%;
  }
}
.hero-img-tiny {
  width: 58%;
  max-width: 58%;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  .hero-img-tiny {
    width: 100%;
    max-width: 100%;
  }
}
.hero-post-img-small {
  width: 60%;
  max-width: 600px;
  margin: 0 auto;
  vertical-align: bottom;
}
@media (max-width: 600px) {
  .hero-post-img-small {
    width: 100%;
    max-width: 100%;
  }
}
.hero-img-landing {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}
.mw-300 {
  max-width: 16.5em;
}
.hero-icon {
  width: 100%;
  max-width: 150px;
}
.security-card {
  background: #111;
  color: #f2f3f7;
}
.security-card .caption {
  color: #a2a5b2;
  font-size: 0.75em;
}
.security-card .caption a {
  text-decoration: underline;
  color: inherit;
}
.security-lock {
  width: 16vw;
  max-width: 90px;
}
@media (max-width: 1024px) {
  .security-lock {
    display: none;
  }
}
.security-card .summary {
  line-height: 140%;
}
.splash-security-card {
  background: #333;
  border-radius: 24px;
  padding: 5em 0;
}
@media (max-width: 1024px) {
  .splash-security-card {
    padding: 2.5em 0.2em;
  }
}
.splash-security-card .header {
  max-width: 80%;
  margin: auto;
  color: #fff;
}
.splash-security-card .summary {
  max-width: 60%;
  margin: auto;
  color: #fff;
}
@media (max-width: 1024px) {
  .splash-security-card .summary {
    max-width: 80%;
  }
}

.showSideBar {
  transform: translateX(0px) !important;
  right: 0px !important;
}

.showBackdrop {
  display: initial !important;
}

.heroCont {
  position: relative;
  padding: 30px 5% 30px 5%;
}

.heroChild {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1400px;
  min-height: 100vh;
}
.vyLoad {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.text-mainHero {
  position: relative;
  background: transparent;
  z-index: 99;
}

.text-mainHero h1 {
  margin-top: 0px;
  margin-bottom: 20px;
  line-height: 1.1;
}

.heroImageMainText {
  position: relative;
  width: auto;
  height: auto;
  max-width: 375px;
  z-index: 99;
}

.heroDes {
  margin-bottom: 40px !important;
}

.text-mainHero a {
  font-size: 20px !important;
  padding: 20px 40px !important;
  border-radius: 84px !important;
  text-decoration: inherit !important;
  transition: none !important;
  background: #06f !important;
  padding: 0.8em 1.5em !important;
  color: #fff !important;
  cursor: pointer !important;
}

@media (max-width: 856px) {
  .heroChild {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 1400px;
    min-height: 100vh;
    padding-top: 80px;
    padding-bottom: 45px;
    min-height: auto;
  }

  .heroImageMainText {
    display: none;
  }
}

@media (min-width: 841px) {
  nav {
    background: #fff !important;
  }
}

@media (max-width: 856px) {
  .heroChild {
    min-height: 350px !important;
  }
}
nav.shrink {
  background: white !important;
}

.bankingSmallText {
  font-weight: 700;
  opacity: 80;
  font-size: 14px;
}

.bankingHeading {
  font-weight: 800;
  margin-bottom: 5px !important;
  padding-bottom: 0px !important;
  font-size: 40px;
  line-height: 1.2;
}

.bankingHeading3 {
  font-size: 35px !important;
}

.bankingDesc {
  font-size: 18px;
  padding-bottom: 30px !important;
}

.bankingFlex {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 0 !important;
  flex-wrap: wrap;
}

.bankingFlex a {
  display: flex;
  align-items: center;
  gap: 10px;
  color: rgb(56, 56, 255);
  line-height: 0;
  font-weight: 600;
}

.gradient {
  background: -webkit-linear-gradient(245deg, #1aa3ff 0%, #b090f5 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  display: inline;
}

@media (max-width: 527px) {
  .bankingHeading {
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 20px !important;
  }

  .bankingDesc {
    font-size: 17px;
    padding-bottom: 20px !important;
  }

  .bankingFlex {
    justify-content: space-between;
    gap: 15px;
  }
}

@media (max-width: 400px) {
  .bankingHeading1 {
    font-size: 22px;
  }

  .bankingHeading2 {
    font-size: 25px;
  }
  .bankingHeading3 {
    font-size: 20px !important;
  }
}

.secondPartCont {
  padding: 60px 5% 60px 5%;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgb(255, 255, 255) 78%,
    rgb(249, 249, 251) 100%
  );
  font-size: 26px;
}

@media (max-width: 540px) {
  .secondPartCont {
    font-size: 20px;
    padding: 30px 5% 30px 5%;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      rgb(255, 255, 255) 78%,
      rgb(249, 249, 251) 100%
    );
  }
  .main-containerBanking {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

footer {
  padding: 2rem 0;
}

@media (max-width: 500px) {
  .round-card-innerBanking {
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 30px !important;
  }
  footer {
    margin-top: -200px !important;
  }
}

.round-card-inner-yellow {
  padding: 30px 25px 60px 30px !important;
  color: white;
}

.yellow-content {
  margin: 0px;
}

.yellow-content h2 {
  font-size: 27px;
}

.main-section > :not(:first-child) {
  margin: 0 !important;
}
@media (max-width: 812px) {
  .flex {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 500px) {
  .round-card-innerBanking {
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 30px !important;
  }

  .round-card-inner-yellow {
    padding: 20px 25px 60px 20px !important;
  }

  footer {
    margin-top: -200px !important;
  }
}

@media (max-width: 768px) {
  .round-card-yellow {
    height: initial !important;
  }

  .round-card-yellow:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: #fff;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

.hidden {
  display: none !important;
}
